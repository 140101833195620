/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        //open the lateral panel
        $('.btn-menu.cd-btn a').on('click', function (event) {
          event.preventDefault();
          $('.cd-panel').addClass('is-visible');
        });
        //clode the lateral panel
        $('.cd-panel').on('click', function (event) {
          if ($(event.target).is('.cd-panel') || $(event.target).is('.cd-panel-close')) {
            $('.cd-panel').removeClass('is-visible');
            event.preventDefault();
          }
        });

      },
      finalize: function () {
        $(".wrap .wrapper .collapse").on("show.bs.collapse hide.bs.collapse", function () {
          $('a[href$="#' + $(this).attr('id') + '"]').find('i').toggleClass('fa-rotate-180');
        });

        var header = $(".banner");

  //       $("#diaporama-mobile, #diaporama").swiperight(function() {
  //     $(this).carousel('prev');
  //   });
  //  $("#diaporama-mobile, #diaporama").swipeleft(function() {
  //     $(this).carousel('next');
  //  });

        $(window).scroll(function () {
          var scroll = $(window).scrollTop();

          if (scroll >= 200) {
            header.removeClass('clearHeader').addClass("darkHeader");
          } else {
            header.removeClass("darkHeader").addClass('clearHeader');
          }
        });
        $(".side-menu .sub-menu").addClass("collapse");
          $(".side-menu .menu-item-has-children > a").append(' <i class="fa fa-angle-down" aria-hidden="true"></i>');

        $(".side-menu .sub-menu").collapse("hide");

        $(".side-menu .menu-item-has-children>a").on("click", function (event) {

          event.preventDefault();
          $(this).next().collapse('toggle');
        });

      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
